'use client';
const defaultStorage = 'sessionStorage';
const storage =
  typeof window !== 'undefined'
    ? window[defaultStorage]
    : {
        getItem: () => {},
        setItem: () => {},
        clear: () => {},
        removeItem: () => {},
      };

const persistenceService = {
  set: (key: string, data: any): void => {
    const rawData = JSON.stringify(data);

    const event: any = new Event('itemInserted');
    const previousValue = storage.getItem(key)
      ? JSON.parse(storage.getItem(key)!)
      : null;

    event.value = rawData;
    event.key = key;
    event.previousValue = previousValue;

    storage.setItem(key, rawData);

    document.dispatchEvent(event);
  },
  get: (key: string): any => {
    const storedData = storage.getItem(key) || null;

    return storedData ? JSON.parse(storedData) : storedData;
  },
  registerEventListener: (
    callback: (data: any) => void,
    values: string[] = [],
  ) => {
    const checkValuesAndUpdate = (event: any) => {
      const newValue = JSON.parse(event.value);
      const hasChangesOnRegisteredValues = Object.keys(newValue).some((val) => {
        return (
          values.includes(val) &&
          event.previousValue &&
          newValue[val] !== event.previousValue[val]
        );
      });

      if (hasChangesOnRegisteredValues) {
        callback(event);
      }
    };
    document.addEventListener('itemInserted', checkValuesAndUpdate, false);

    return function unRegister() {
      document.removeEventListener('itemInserted', checkValuesAndUpdate, false);
    };
  },
  clear(key: string) {
    storage.setItem(key, '');
  },
  clearAll() {
    storage.clear();
  },
  remove(key: string) {
    storage.removeItem(key);
  },
};

export default persistenceService;
